$padding: 2rem;
$margin: 4rem;
$border-radius: 0.75rem;

@media (prefers-reduced-motion) {
}

@media (max-width: 700px) {
  $margin: 1rem;
  $padding: 1rem;
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}

@media (prefers-color-scheme: dark) {
}
