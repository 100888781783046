@use "./fonts";
@use "./colors";
@use "./spacings";
@use "./animations";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar,
  html {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.limit_text1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.limit_text2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.moneyVal::before {
  content: "$";
}

html {
  max-width: 100vw;
  scroll-behavior: smooth;
}

body {
  font-family: "Space Grotesk", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  max-width: 100vw;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  color: colors.$dark_shades;
  font-weight: bold;
  text-align: center;
  font-size: calc(3rem + (2.5 * ((100vw - 375px) / 1225)));
}

h2 {
  font-weight: 600;
  color: colors.$main_brand_color;
  text-align: center;
  font-size: calc(2rem + (2.5 * ((100vw - 375px) / 1225)));
}

h3 {
  font-size: calc(1.5rem + (2.5 * ((100vw - 375px) / 1225)));
  font-weight: 500;
}

h4 {
  font-size: calc(1rem + (2.5 * ((100vw - 375px) / 1225)));
  font-weight: 500;
}

p {
  color: colors.$main_brand_color;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::selection {
  background: black;
  color: colors.$dark_accent;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: none;
  padding: 0;
  margin: 0;
}

.spacer {
  padding: 2rem;
  background: transparent;
}

.marginMenu {
  margin-top: 4rem;
}

.fullScreen {
  min-height: 100lvh;
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.relativeContainer {
  position: relative;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: column;
  flex-wrap: nowrap;
}

.flexContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.flexColumn {
  flex-direction: column;
}

.flexRow {
  flex-direction: row;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexWrapReverse {
  flex-wrap: wrap-reverse;
}

.flexSpaceAround {
  justify-content: space-around;
}

.flexSpaceEvenly {
  justify-content: space-evenly;
}

.flexSpaceBetween {
  justify-content: space-between;
}

.grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: spacings.$margin;
}

.maxWidth {
  max-width: min(100%, 1600px);
  margin: auto;
}

@media (hover: hover) and (pointer: fine) {
}

@media (prefers-reduced-motion) {
}

@media (max-width: 1200px) {
  h1 {
    text-align: center;
    font-size: calc(2rem + (1 * ((100vw - 375px) / 1225)));
  }

  h2 {
    font-size: calc(1.5rem + (1 * ((100vw - 375px) / 1225)));
    text-align: center;
  }

  h3 {
    font-size: calc(1.25rem + (1 * ((100vw - 375px) / 1225)));
    font-weight: 700;
  }

  .styledButton {
    margin-bottom: 1rem;
    font-size: medium;
  }

  .desktop {
    height: 0;
    width: 0;
    overflow: hidden;
    opacity: 0;
    display: none;
    padding: 0;
    margin: 0;
  }

  .mobile {
    display: block;
    width: auto;
    height: auto;
    opacity: 1;
  }

  .flexContainer {
    justify-content: center;
  }

  .flexSpaceBetween {
    justify-content: center;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
    gap: 1rem;
    width: 100vw;
  }
}

@media (prefers-color-scheme: dark) {
}
